import React, {useEffect, useState} from 'react';
//import { RootContext } from '../../App';
import styled from 'styled-components';
import cover_whitepaper from '../../assets/images/cover_whitepaper.png';
//import documentWPProjects from '../../assets/White_Paper_Jogjatron_Projects.pdf';
import icons8_doc_project_48 from '../../assets/images/icons8-doc-project-48.png';

const ImgCoverWhitepaper = styled.img`
`;

ImgCoverWhitepaper.defaultProps = {
  src: cover_whitepaper,
};

const Imgwhitepaperproject = styled.img`
  width: 25px;
  height: 25px;
`;

Imgwhitepaperproject.defaultProps = {
  src: icons8_doc_project_48,
};

function FourthContent() {
  const [varD1, setvarD1] = useState('');
  const [varD2, setvarD2] = useState('');
  const [varD3, setvarD3] = useState('');

  const handle2022 = () => { 
    setvarD1('block');
    setvarD2('none');
    setvarD3('none');
  }
  const handle2023 = () => { 
    setvarD1('none');
    setvarD2('block');
    setvarD3('none');
  }
  const handle2024 = () => { 
    setvarD1('none');
    setvarD2('none');
    setvarD3('block');}

    return (
      
            <section id="roadmap" name="roadmap">
              <div className="box_4">
                {/* <div className="roadmap">
                  <div className="container">
                    <div className="judul">
                      <div className="row">
                        <div className="col-12">
                          <h2><span>Jogjatron </span>Roadmap</h2>
                        </div>
                      </div>
                    </div>
                    <div className="tabs">
                      <div className="menu">
                        <div className="row">
                          <div className="col-2">
                            <div className="active-text">
                              <button  value="switch" onClick={handle2022}>2022</button>
                            </div>
                          </div>
                          <div className="col-2">
                            <div className="text">
                              <button value="switch" onClick={handle2023}>2023</button>
                            </div>
                          </div>
                          <div className="col-2">
                            <div className="text">
                              <button  value="switch" onClick={handle2024}>2024</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                        
                  <section id="roadmap-2022" style={{display:(varD1)}}>
                    <div className="roadmap-detail">
                      <div className="row">
                        <div className="col-lg-4 col-sm-12">
                          <div className="quarter">
                            <h2>Q2</h2>
                          </div>
                          <div className="wrapper-detail">
                            <div className="detail">
                              <p> 
                                ● Menentukan ide model bisnis Diskusi dengan blockchain expert  <br></br>
                                ● Pembentukan tim pengembang teknis Whitepaper versi 2.0 <br></br>
                                ● Finalisasi Token Spesifikasi Infrastruktur dasar teknologi  <br></br>
                                ● MOU dengan 5 musisi 
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-12">
                          <div className="quarter">
                            <h2>Q3</h2>
                          </div>
                          <div className="wrapper-detail">
                            <div className="detail">
                              <p> 
                                ● Community engagement creation Private sale <br></br>
                                ● Build and launch website Kemitraan dengan ekosistem musik Test contract code <br></br>
                                ● Private Sale Tahap 2 <br></br>
                                ● MOA travel agent <br></br>
                                ● Smart contract ready Implementasi eKYC <br></br>
                                ● MOU PHRI <br></br>
                                ● Testimoni Menteri Pariwisata
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-12">
                          <div className="quarter">
                            <h2>Q4</h2>
                          </div>
                          <div className="wrapper-detail">
                            <div className="detail">
                              <p> 
                                ● Token presale <br></br>
                                ● Listing di pancakeswap <br></br>
                                ● MOA dengan pengelola hotel MOA kesultanan Jogja <br></br>
                                ● MOA dengan Hedge Fund MOA Galeri Seni <br></br>
                                ● Pengajuan Audit Certik <br></br>
                                ● Music Ownership Stable Release Room Ownership Stable Release 12.000 member grup telegram Podcast YouTube Channel Launch AMA Big Event ke 1 <br></br>
                                ● Jogjatron Konser Part 1 <br></br>
                                ● Testimoni Menteri BUMN <br></br>
                                ● MOU dengan 30 Musisi <br></br>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section id="roadmap-2023" style={{display:(varD2)}}>
                    <div className="roadmap-detail">
                      <div className="row">
                        <div className="col-lg-3 col-sm-12">
                          <div className="quarter">
                            <h2>Q1</h2>
                          </div>
                          <div className="wrapper-detail">
                            <div className="detail">
                              <p> 
                              ● MOA Cafe Restoran<br></br>
                              ● Beta Launch jogjatron MO<br></br>
                              ● Ceremonial Event with Kesultanan Yogyakarta MOA Paniradya Kaistimewan<br></br>
                              ● Arisan stable release<br></br>
                              ● Digital Signature Yasaya Integration<br></br>
                              ● 100.000 follower Instagram<br></br>
                              ● Twitter Follower 5.000<br></br>
                              ● Deddy Corbuzier Podcast<br></br>
                              ● TV commercial program<br></br>
                              ● MOU dengan 50 musisi<br></br>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-12">
                          <div className="quarter">
                            <h2>Q2</h2>
                          </div>
                          <div className="wrapper-detail">
                            <div className="detail">
                              <p> 
                              ● Kerjasama Official Peruri<br></br>
                              ● NFT Marketplace Stable Release<br></br>
                              ● AMA Big Event ke 2<br></br>
                              ● Event with Komunitas Animator klan Manca Negara<br></br>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-12">
                          <div className="quarter">
                            <h2>Q3</h2>
                          </div>
                          <div className="wrapper-detail">
                            <div className="detail">
                              <p> 
                                ● Listing Bonafid di cex Jogja toBalitron Event<br></br>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-12">
                          <div className="quarter">
                            <h2>Q4</h2>
                          </div>
                          <div className="wrapper-detail">
                            <div className="detail">
                              <p> 
                                ● Ojek Online Integration Stable<br></br>
                                ● Jogjatron Game Release<br></br>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section id="roadmap-2024" style={{display:(varD3)}}>
                    <div className="roadmap-detail">
                      <div className="row">
                        <div className="col-lg-4 col-sm-12">
                          <div className="quarter">
                            <h2>Q1</h2>
                          </div>
                          <div className="wrapper-detail">
                            <div className="detail">
                              <p> 
                                ● Metaverse for Public Ownership Jogjatron Metaverse Versi 1 Stable
                              </p>
                            </div>
                          </div>
                        </div>
                        
                      </div>
                    </div>
                  </section>

                  </div>
                </div> */}

            <div className="whitepaper-sec">
            <div className="container">
                <div className="wrap-box">
                    <div className="row">
                        <div className="col-sm-12 col-lg-5">
                          <ImgCoverWhitepaper />
                        </div>
                        <div className="col-sm-12 col-lg-7">
                        <h2> Download Whitepaper <br></br>
                            <span>Jogjatron Projects</span>
                        </h2>
                            <p>
                                Cari tahu lebih lanjut mengenai jogjatron, peluang bisnis dan proyek jogjatron
                            </p>
                            {/* not use */}
                            {/* <a href={documentWPProjects} target="_blank"  rel="noreferrer" download className="btn btn-custom2 btn-primary">
                              <Imgwhitepaperproject />Whitepaper Projects
                            </a> */}
                            </div>
                          </div>
                        </div>
                      </div>
            </div>
            </div>
            </section>
            )
};


export default FourthContent;