import React from "react";
import styled from 'styled-components';



const CountdownDiv = styled.div`
  text-align: center; 
  margin-bottom: 50px;
`;
// Renderer callback with condition
const AirdropCountdownComponent = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a complete state
    return (
     <>
      
      <div className="wrap-text">
        <center>
          <h3 className="animate-charcter"> Event telah berakhir</h3>
        </center>
       
      </div>
      <div className="countdown">
      <div className="row">
            <div className="col-lg-9">
                <h5>WHITEPAPER</h5>
                <h3>Download Whitepaper</h3>
            </div>
            <div className="col-lg-3">
              <a href={"https://drive.google.com/file/d/1YohbzgVkCRa7MbK_NdaFcVJYlndbcun-/view"} target="_blank"  rel="noreferrer" download>
                <button    className="whitepaper-btn">Whitepaper Projects</button>
              </a>
            </div>
          </div>
      </div>
     
      </>
    );
  } else {
    // Render a countdown
    return (
      <>
      
      <div className="banner">
            Airdrop ends In : 
      </div>
      <div className="countdown">
          <CountdownDiv className='row'>
                <div className="col">
                    <h2>{String(days)}</h2>
                    <p>HARI</p>
                </div>
                <div className="col">
                      <h2>{String(hours)}</h2>
                      <p>JAM</p>
                </div>
                <div className="col">
                      <h2>{String(minutes)}</h2>
                      <p>MENIT</p>
                </div>
                <div className="col">
                      <h2>{String(seconds)}</h2>
                      <p>DETIK</p>
                </div>
          </CountdownDiv>
          <div className="row">
              <div className="col-lg-9">
                  <div className="wrap-text">
                      <h2 className="animate-charcter"> Join Airdrop Now !</h2>
                  </div>
              </div>
              <div className="col-lg-3">
                <a href="#form-airdrop" >
                  <button className="whitepaper-btn" >Join Airdrop</button>
                </a>
              </div>
          </div>
         
        </div>
                                
      </>
    );
  }
};
//1 second == 1000

export default AirdropCountdownComponent ;

//const definedDay = 24;      //24 == 1day
//const definedHour = 60;   //60 == 1 hour
//const definedMin = 60;
//const definedSecond = 1000; //1000 == 1 second


//const totalTime = 7*(definedDay * definedHour * definedMin * definedSecond);

//ReactDOM.render(
//  <Countdown date={Date.now() + totalTime} renderer={CountdownComponent} />,
//  document.getElementById("root")
//);