import React, { useState, useEffect } from "react";
import Axios from 'axios';
import styled from 'styled-components';
import MaskotGatotVer01Rev02 from '../../assets/images/MaskotGatotVer01Rev02.png';
//useTasks to show, useTasksDispatch to edit
//const dispatch = useTasksDispatch();
//  dispatch({  walletAddress : 'newvalue'  })
//const tasks = useTasks();
import { useTasks, useTasksDispatch } from "../../TasksContext";
import Swal from "sweetalert2";

const ImgMascot = styled.img`
`;

ImgMascot.defaultProps = {
  src: MaskotGatotVer01Rev02,
};

const BtnLoad = styled.button`
  background-color: #04AA6D; /* Green background */
  border: none; /* Remove borders */
  color: white; /* White text */
  padding: 12px 24px; /* Some padding */
  font-size: 16px; /* Set a font-size */
`;




const REACT_APP_PROD_ROUTE = process.env.REACT_APP_PROD_ROUTE || 'http://127.0.0.1:8000/'

function PrivateSale () {
  const tasks = useTasks();
  const [totalToken, settotalToken] = useState();
  const [dataBuyer, setdataBuyer] = useState([]);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [privateSaleLoading, setprivateSaleLoading] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  }


  async function handleRegisterPrivatesale() {
    if (!tasks.walletAddress){
      alert('tidak ada address wallet terkoneksi');
    }
    else if (!document.getElementById('email').value){
      alert('silahkan masukkan email');
    }else {
      const response = await registerPrivateSale();
      
      if (response.status === 201){
          //alert("Berhasil mendaftar private sale JAGO. Pihak Jogjatron akan menghubungi anda via Email !")
          Swal.fire({
            icon: 'success',
            title: 'Sukses',
            text: 'Berhasil mendaftar private sale JAGO. Pihak Jogjatron akan menghubungi anda via Email !',
            confirmButtonText: 'Ok'
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload(false);
              }
          })
          
          
        } else if (response.status === 200) {
          alert(response.data.errors.error);
          Swal.fire({
            icon: 'error',
            title: 'Pendaftaran gagal',
            text: response.data.errors.error,
            confirmButtonText: 'Ok'
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload(false);
              }
          })
      }
    }
  }
  async function registerPrivateSale (){
    let response ;
    let headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }
    
    let formData = new FormData();
    formData.append('wallet_address', tasks.walletAddress);
    formData.append('email', email);
    formData.append('amount', 0);
    formData.append('transaction_hash', "-");

    await Axios.post(`${REACT_APP_PROD_ROUTE}api/private-sale/store-buyer`,formData, {
        headers,
        withCredentials: true,
    })
    .then(res => {
        const data = res;  
        response = data
    })
    .catch((error) => {
      console.log(error);
      response = error.response;
    
    });
    return response;
  }


  const getTotalToken = async () => {
    Axios.get(`${REACT_APP_PROD_ROUTE}api/private-sale/total-token`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'application/json',
    },
    withCredentials: true,
    })
      .then(res => {
        const totalToken = (res.data).toLocaleString();
        settotalToken(totalToken);
      })
      .catch(err => {
        console.log(err)
      })
    }

   const getDataBuyer = async () => {
      Axios.get(`${REACT_APP_PROD_ROUTE}api/private-sale/list-buyer`, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json',
      }, 
      withCredentials: true,
    })
        .then(res => {
          setdataBuyer(res.data.data);
          setLoading(true);
        })
        .catch(err => {
          console.log(err)
        })
      }

      useEffect(() => {
        
        getTotalToken()
        getDataBuyer()
      }, []);

      useEffect(() => {
        if(email && tasks.walletAddress){
          setIsValid(true);
        }else{
          setIsValid(false);
        }
      },);


      return (
        <section id="token-info">
          <div className="box_2">
            <div className="container">
              <div className="title">
                  Private Sale Token Tersedia :
              </div>

              <div className="box-info">
                  <div className="token-left">
                    {totalToken}
                  </div>
                  <div className="token">
                    / 3.000.000 JAGO
                  </div>
                </div>
        <div className="form-privatesale">
          <div className="col-lg-3">
            <ImgMascot /> 
          </div>
          <div className="col-lg-9">
            <div className="box-form">
                <div className="title">
                    Private Sale JAGO
                </div>
                <div className="h5">
                    Connect ke Jogjatron dan isikan email
                </div>
                <form action="">
                    <div className="form-floating mb-3 col-lg-12 col-sm-12 col-md-9">
                    {!tasks.walletAddress 
                        ? (
                          <input type="" className="form-control" id="floatingInput" value="" disabled/>
                        ):(
                          <input type="" className="form-control" id="floatingInput" value={tasks.walletAddress} disabled></input>
                        )
                        }
                        <label htmlFor="floatingInput">Wallet address</label>
                    </div>
                    <div className="form-floating mb-3 col-lg-12 col-sm-12 col-md-9">
                        <input 
                          type="email" 
                          className="form-control" 
                          id="email" 
                          placeholder="name@example.com"
                          onChange={handleEmailChange} />
                        <label htmlFor="floatingInput">Email</label>
                    </div>
                </form>
                <div className="h5">
                    Jogjatron akan menghubungi calon pembeli melalui email.
                </div>
                <div className="d-grid gap-2 col-12">
                  {isValid
                    ? <button className="btn btn-primary-sale" type="button" onClick={handleRegisterPrivatesale}>
                    DAFTAR PRIVATE SALE</button>
                    : <button className="btn btn-primary-sale" type="button" disabled>
                    DAFTAR PRIVATE SALE</button>
                  }
                         
                                      
                </div>
              </div>
            </div>
          </div>



          <div className="title-table">
            List Pembeli Private Sale
          </div>
              <div className="table-box">
                <div className="table-responsive">
                  <table className="table table-borderless">
                    <thead>
                      <tr>
                        <th scope="col">Wallet Address</th>
                        <th scope="col">Jumlah Beli</th>
                        <th scope="col">Transaction Hash</th>
                      </tr>
                    </thead>

                {loading && 
                dataBuyer.map((data) => (
                  <tbody className="table-group-divider" key={data.id}>
                   
                    <tr >
                    {
                      data.amount != 0
                      ?<>
                        <td>{data.wallet_address}</td>
                        <td>{(parseInt(data.amount)).toLocaleString()}</td>
                        <td>{data.transaction_hash}</td>
                      </>
                      :<></>
                    }
                      
                    </tr>
                  </tbody>
                  
                
                ))}
              </table>
          </div>
        </div>
        </div>
        </div>
        </section>
      )
}

export default PrivateSale;
