import React from 'react'
import './App.css';
import RoutesConfig from './config/router';




function App() {
  
  return (
    <RoutesConfig />  
  );
}

export default App;
