import React, { useEffect, useState } from 'react';
import FirstContentHero from './FirstContentHero';
import AirdropCard from './AirdropCard';
import PresaleContent from './PresaleContent';
import SecondContent from './SecondContent';
import ThirdContent from './ThirdContent';
import FourthContent from './FourthContent';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
//NFTMO ANNOUNCEMENT
//import imageAnnouncement from '../../assets/images/1675401135.png';

//PRESALE ANNOUNCEMENT
import imageAnnouncement from '../../assets/images/presale.jpg';
import { Link } from 'react-router-dom';

function Homepage(){
  const [showAnnouncement, setShowAnnouncement] = useState("");

  const handleCloseAnnouncement = () => {
    setShowAnnouncement(false);
  }

  useEffect(() => {
    setShowAnnouncement(true);
   }, []);

  return(
    <>
    <Modal
      size="lg"
      show={showAnnouncement}
      onHide={handleCloseAnnouncement}
      aria-labelledby="example-modal-sizes-title-lg"
      style={{textAlign: 'center'}}
    >
    <Modal.Header closeButton>
      <Modal.Title id="example-modal-sizes-title-lg">
        Jogjatron Announcement
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <img src={imageAnnouncement} style={{maxWidth: '100%'}}/>
    </Modal.Body>
    <Modal.Footer>

        {/* 
        /NFTMO ANNOUNCEMENT
        <Button href={"https://nftmo.jogjatron.com/"} target="_blank" variant="primary" onClick={handleCloseAnnouncement} style={{backgroundColor: '#35a387'}}>
          Kunjungi NFT Music Owner
        </Button> */}

        
        {/* PRESALE ANNOUNCEMENT */}
        <Button href={"https://www.pinksale.finance/launchpad/0x83b7e0aAF310CB8c52CA82e0fB93111F950E3Fc0?chain=BSC"} target="_blank" variant="primary" onClick={handleCloseAnnouncement} style={{backgroundColor: '#35a387'}}>
          Lihat Presale
        </Button>
      </Modal.Footer>
    </Modal>

    {/**Header connect wallet and navbar */}
    <FirstContentHero />

    {/**Optional presale card */}
    <AirdropCard />
    
    {/** */}
    <PresaleContent />

    {/**presale Info, mascot and about */}
    {/* <SecondContent />   */}

    {/**upcoming project */}
    <ThirdContent />

    {/**roadmap */}
    {/* <FourthContent /> */}
        
    {/**Optional presale card */}
    <AirdropCard />

  </>
  )
}
export default Homepage;