import styled from "styled-components";
import noWalletConnected from "../../assets/images/no-wallet.svg";
import background from '../../assets/images/bg.png';



const NoWalletConnectedImg = styled.img``;
NoWalletConnectedImg.defaultProps = {
    src: noWalletConnected
}

const DivAirdr = styled.div`
background: url(${background});
padding-top: 10rem;
padding-bottom: 10rem;
background-size: cover;
background-position: center center;
background-repeat: no-repeat;
`;


function NoMatch(){
 
  return(
    <>
    
    <DivAirdr>
      <section className="airdop">
        <div className="container">
          <div style={{
            height: "500px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
            }}>
            <NoWalletConnectedImg alt="" />
          </div>
        </div>
      </section>
    </DivAirdr>
   
  </>
  )
}

export default NoMatch;