import React, {useState} from 'react';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { truncateAddress } from '../../utils/utils.js';

const DivIconTop = styled.div`
  margin-right: auto !important;
  @media (max-width: 768px) {
    display: none;
  }
`;
const TextAddress = styled.a `
  font-size: 20px;
  margin-top: 10px;
  padding-right: 10px;
  text-align: right;
  color: white;
`;

function NavButtonConnect(props){

  return(
              <div className="container py-3">
                <div className="row align-items-center">
                  <div className="col-sm-12 col-lg-6">
                    <div className="d-flex mr-auto">
                      <a href="mailto:hi@jogjatron.com" className="d-flex align-items-center mr-4">
                        <span className="icon-envelope mr-2"></span>
                        <span className="d-none d-md-inline-block">hi@jogjatron.com</span>
                      </a>
                      <a href="#" className="d-flex align-items-center mr-auto">
                        <span className="icon-phone mr-2"></span>
                        <span className="d-none d-md-inline-block">+62 811 2641 112</span>
                      </a>
                      <a href="https://twitter.com/JogjaTronNFT?s=20&t=fjtxBhfn7bXNdgvEHl-HgA" target="_blank" className="p-2 pl-0"><span className="icon-twitter"></span></a>
                      <a href="https://t.me/jogjatron" target="_blank" className="p-2 pl-0"><span className="icon-telegram"></span></a>
                      <a href="https://www.instagram.com/jogjatron/" target="_blank" className="p-2 pl-0"><span className="icon-instagram"></span></a>
              
                      
                    </div>
                  </div>
                  
                  <div className="col-sm-12 col-lg-6 text-right">
                    <div className="mr-auto">
                      <div className="wallet-address">
                        {` ${truncateAddress(props.connectTo)} `}
                        {!props.connectTo ? (
                          <button className="btn btn-grad" type="button" onClick={props.onClickConnect}>Connect Wallet</button>
                        ) :(
                          <button className="btn btn-disconnect" type="button" onClick={props.onClickDisconnect}>Disconnect</button>
                        )}
                                               
                      </div>
                    </div>
                    
                  </div>  
                </div>
              </div>
  )
}

export default NavButtonConnect;