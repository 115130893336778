import React from "react";
import styled from 'styled-components';
import {Link} from 'react-router-dom';


const CountdownDiv = styled.div`
  text-align: center; 
  margin-bottom: 50px;
`;

// Renderer callback with condition
const PresaleStartInComponent = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state
      return (
       <>
            <div className="wrapper">
                <center>
                    Presale Token JAGO Berakhir pada 
                 </center>

                <div className="wrap-countdown">
                    <div className="row">
                        <div className="col">
                            <h2>{String(days)}</h2>
                            <p>hari</p>
                        </div>
                        <div className="col">
                            <h2>{String(hours)}</h2>
                            <p>jam</p>
                        </div>
                        <div className="col">
                            <h2>{String(minutes)}</h2>
                            <p>menit</p>
                        </div>
                        <div className="col">
                            <h2>{String(seconds)}</h2>
                            <p>detik</p>
                        </div>
                    </div>
                </div>
											<div>
												<a href='https://www.pinksale.finance/launchpad/0x83b7e0aAF310CB8c52CA82e0fB93111F950E3Fc0?chain=BSC'  target="_blank" >
												<button className="btn btn-primary-presale" type="button">
													LIHAT PRESALE 
												</button>
												</a>
												{/* <button className="btn btn-primary-tutorial" type="button">
													Lihat cara pembelian presale 
												</button> */}
                    </div>
                </div>
        </>
      );
    } else {
      // Render a countdown
      return (
        <>
            <div className="wrapper">
                <center>
                    Presale Token JAGO akan segera dimulai ! 
                 </center>

                <div className="wrap-countdown">
                    <div className="row">
                        <div className="col">
                            <h2>{String(days)}</h2>
                            <p>hari</p>
                        </div>
                        <div className="col">
                            <h2>{String(hours)}</h2>
                            <p>jam</p>
                        </div>
                        <div className="col">
                            <h2>{String(minutes)}</h2>
                            <p>menit</p>
                        </div>
                        <div className="col">
                            <h2>{String(seconds)}</h2>
                            <p>detik</p>
                        </div>
                    </div>
                </div>
                <div>
										<a href='https://www.pinksale.finance/launchpad/0x83b7e0aAF310CB8c52CA82e0fB93111F950E3Fc0?chain=BSC'  target="_blank" >
										<button className="btn btn-primary-presale" type="button">
											LIHAT PRESALE 
										</button>
										</a>
										{/* <button className="btn btn-primary-tutorial" type="button">
											Lihat cara pembelian presale 
										</button> */}
                </div>
                </div>

                                  
        </>
      );
    }
  };

  export default PresaleStartInComponent;