import React from 'react';
import Countdown from "react-countdown";
import AirdropCountdownComponent from './AirdropCountdownComponent';

{/**
const definedDay = 24;      //24 == 1day
const definedHour = 60;   //60 == 1 hour
const definedMin = 60;
const definedSecond = 1000; //1000 == 1 second

const totalTime = 7*(definedDay * definedHour * definedMin * definedSecond);
 */}

class WrapperAirdrop extends React.Component {
  render() {
    
    return (   
        <>
          <div className="wrapper_airdrop">
            <Countdown date={Date.now() + this.props.dataFromParent} renderer={AirdropCountdownComponent} />
          
          
          </div>       
          
          
        </>
      
        )
    }
       
};

export default WrapperAirdrop;