import React from 'react';
import styled from 'styled-components';
import LogoJogjatron_x7 from '../../assets/LogoJogjatron_x7.png';
import icons8_document_48 from '../../assets/icons8-whitepaper-token-60.png';
import icons8_airdrop_60 from '../../assets/icons8-airdrop-60.png';
import background_landingpage from '../../assets/background_landingpage.png';
// not use, pindah ke drive link
//import documentWP from '../../assets/White_Paper_Token_JAGO.pdf';
//import documentWPProjects from '../../assets/White_Paper_Jogjatron_Projects.pdf';
import icons8_doc_project_48 from '../../assets/images/icons8-doc-project-48.png';
import icon_roadmap from '../../assets/images/roadmap.png';
import Swal from 'sweetalert2';

const DivHero = styled.div`
  background: url(${background_landingpage}); 
  height: 100%;
  min-height: 100px;
  overflow: hidden;
  padding-bottom: 100px;
  width: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
`;

const ImgLogo = styled.img`
`;

ImgLogo.defaultProps = {
  src: LogoJogjatron_x7,
};

const Imgwhitepaper = styled.img`
  width: 25px;
  height: 25px;
`;

Imgwhitepaper.defaultProps = {
  src: icons8_document_48,
};

const Imgwhitepaperproject = styled.img`
  width: 30px;
  height: 30px;
`;

Imgwhitepaperproject.defaultProps = {
  src: icons8_doc_project_48,
};

const Imgairdrop = styled.img`
  width: 30px;
  height: 30px;
`;

Imgairdrop.defaultProps = {
  src: icons8_airdrop_60,
};

const Imgroadmap = styled.img`
  width: 30px;
  height: 30px;
`;

Imgroadmap.defaultProps = {
  src: icon_roadmap,
};

const BtnAbout = styled.button`
 
`;
function FirstContentHero()  {

    const handleBtnTokenomic = () => {
      Swal.fire({
        icon: 'info',
        title: 'Nantikan Tokenomic Versi 3',
        showConfirmButton: false,
        timer: 1500
      })
    }

  
    return (
       
    <DivHero >
      <section name="hero" id="hero">
          <div className="container">
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="header-text">
                        Ekosistem NFT dan Metaverse dari Yogyakarta untuk Dunia
                    </div>
                </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12">
                  <div className="header-subtext">
                      Bertekad menjadikan Yogyakarta sebagai contoh baik implementasi teknologi blockchain
                  </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12">
                  <div className="cta-button">
                    <a href={"https://drive.google.com/file/d/1-ISKFT2EkUnVBtFzjcwsYIgNvN_aT0AY/view"} target="_blank"  rel="noreferrer" download className="btn btn-custom btn-primary"  style={{marginLeft:1+"rem"}}>
                      <Imgwhitepaper />   Tokenomic
                    </a> 
                    {/* <a href="#" className="btn btn-custom btn-primary" onClick={handleBtnTokenomic}>
                      <Imgwhitepaper />
                      Tokenomic
                    </a> */}
                    <a href={"https://drive.google.com/file/d/1YohbzgVkCRa7MbK_NdaFcVJYlndbcun-/view"} target="_blank"  rel="noreferrer" download className="btn btn-custom btn-primary" style={{marginLeft:1+"rem"}}>
                      <Imgwhitepaperproject />         Whitepaper Projects
                    </a>

                    <a href={"https://drive.google.com/file/d/1rXJhJZ8xhAGDHTK_NY8W5m8Xy8JJVgvi/view"} target="_blank"  rel="noreferrer" download className="btn btn-custom-roadmap btn-primary"  style={{marginLeft:1+"rem"}}>
                      <Imgroadmap />     Roadmap           <span> New</span>                                                                                                                         
                    </a>
                    
                  </div>
              </div>
            </div>
            
          </div>
      </section>
    </DivHero >
    
    )
}

export default FirstContentHero;