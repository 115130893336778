import React from 'react';
import styled from 'styled-components';
import d_cryptocurrency_rendering_design from '../../assets/images/d_cryptocurrency_rendering_design.png';
import MaskotGatotVer01Rev01 from '../../assets/images/MaskotGatotVer01Rev01.png';
import icons8_document_48 from '../../assets/icons8-whitepaper-token-60.png';
import AboutInfo from './AboutInfo';
import MascotInfo from './MascotInfo';
import PrivateSale from './PrivateSale';

const ImgAbout = styled.img`
`;

ImgAbout.defaultProps = {
  src: d_cryptocurrency_rendering_design,
};

const ImgMascot = styled.img`
`;

ImgMascot.defaultProps = {
  src: MaskotGatotVer01Rev01,
};

const Imgwhitepaper = styled.img`
  width: 25px;
  height: 25px;
`;

Imgwhitepaper.defaultProps = {
  src: icons8_document_48,
};

function SecondContent () {
  
            return (   
              <section name="token-info" id="token-info"  >
              <div className="box_2" >
                <div className="container" >
                  
                {/* <PresaleInfo /> */}
                <PrivateSale />
                <AboutInfo />
                <MascotInfo />

                </div>
              </div>
            </section>
            )
};

export default SecondContent;