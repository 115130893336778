
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import LogoJogjatron_x7 from '../../assets/LogoJogjatron_x7.png';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';


const ImgLogo = styled.img`
`;

ImgLogo.defaultProps = {
  src: LogoJogjatron_x7,
};

function NavbarOrdi(){
  const navigate = useNavigate();

  const handleTokenInfo= () => {
    navigate("/");
  }
  
    return (
      <div id="sticky-wrapper" className="site-navbar site-navbar-target js-sticky-header">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-2">
              <h1 className="my-0 site-logo">
                <Link to={`/`}>
                  <ImgLogo />
                </Link>
              </h1>
            </div>
            <div className="col-10">
              <nav className="site-navigation text-right" role="navigation">
                <div className="container">
                  <div className="d-inline-block d-lg-none ml-md-0 mr-auto py-3">
                    <Link to={`/`} className="site-menu-toggle js-menu-toggle text-white">
                      <span className="icon-menu h3"></span>
                    </Link>
                  </div>

                  <ul className="site-menu main-menu js-clone-nav d-none d-lg-block"> 
                    <li><a href={`/`} className="active" >Home</a></li>
                    <li><a href={`/#token-info`} className="nav-link">Token Jago</a></li>         
                    <li><a href={`/#about`} className="nav-link">Tentang Kami</a></li>
                    <li>
                    <div className="dropdown">
                      <a className="nav-link">Project</a>
                      <div className="dropdown-content">
                      <a href={`/Released-project`} >Released Project</a>
                      <a href={`/#upcoming-project`} >Upcoming Project</a>
                      </div>
                    </div>
                    {/* </li>
                    <li><a href={`/Released-project`} className="nav-link">Released Project</a></li>
                    <li><a href={`/#upcoming-project`} className="nav-link">Upcoming Project</a></li>
                    <li> */}
                    <div className="dropdown">
                      <a className="nav-link">Gitbook Jogjatron</a>
                      <div className="dropdown-content">
                      <a href="https://whitepaper.jogjatron.com/" target="_blank">Gitbook Whitepaper</a>
                      <a href="https://tokenomic.jogjatron.com/" target="_blank">Gitbook Tokenomic</a>
                      <a href="https://roadmap.jogjatron.com/" target="_blank">Gitbook Roadmap</a>
                      </div>
                    </div>
                    </li>
                    <li><a href={`https://nftmo.jogjatron.com/blogs`} className="nav-link">Blogs</a></li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
  )
}

export default NavbarOrdi;