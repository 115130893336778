import React from 'react';
import styled from 'styled-components';
import image_tugu from '../../assets/images/image_tugu.png';


const ImgAbout = styled.img`
  width: 370px;
  border-radius: 20px;
`;

ImgAbout.defaultProps = {
  src: image_tugu,
};


function AboutInfo() {
            return (   
                  <div className="about" id="about">
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-7">
                          <h3> Tentang <span>Jogjatron</span></h3>
                          <p>Jogjatron hadir untuk menghadirkan implementasi teknologi blockchain agar bisa lebih 
                          diterima oleh masyarakat dalam ruang lingkup yang tidak terlalu luas yakni Yogyakarta untuk 
                          meningkatkan level keberhasilan implementasi nya. Tim berusaha untuk berpikir realistis, 
                          praktis sekaligus inovatif menerobos lapisan masyarakat dan berbagai sektor kehidupan masyarakat. </p>
                          <p>Daerah Istimewa Yogyakarta adalah wilayah implementasi yang dengan seksama kami pilih sebagai wilayah kerja  
                          yang kami yakini akan menjadi contoh sukses implementasi teknologi blockchain yang bisa menginspirasi 
                          wilayah bahkan negara lain.</p>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-5">
                        <ImgAbout />
                      </div>
                    </div>
                  </div>
            )
};

export default AboutInfo;