import React, {useState} from 'react';
//import { RootContext } from '../../App';
import styled from 'styled-components';
import Background2 from '../../assets/images/background-2.png';
import AllProject from '../../assets/images/project-all.png';

import MO from '../../assets/images/Happy-Hits.jpg';
import Arizan from '../../assets/images/arzan.jpg';
import RO from '../../assets/images/Listing-Hotel-Rooms-on-Airbnb-315x211.jpg';
import NFTTicket from '../../assets/images/nft-ticket.jpg';
import NFTArtwork from '../../assets/images/nft-artwork.png';

const DivUpcomingPr = styled.div`
  background: url(${Background2});
  background-repeat: no-repeat;
  background-size: cover;
`;

const ImgProjectAll = styled.img`
`;

ImgProjectAll.defaultProps = {
  src: AllProject, 
}

const ImgMO = styled.img `
`;
ImgMO.defaultProps = {
  src: MO,
}

const ImgRO = styled.img `
`;
ImgRO.defaultProps = {
  src: RO,
}

const ImgArizan = styled.img `
`;
ImgArizan.defaultProps = {
  src: Arizan,
}

const ImgNFTTicket = styled.img `
`;
ImgNFTTicket.defaultProps = {
  src: NFTTicket,
}

const ImgNFTArtwork = styled.img `
`;
ImgNFTArtwork.defaultProps = {
  src: NFTArtwork,
}

function ThirdContent() {
  const [show, setShow] = useState(false);
  let section_upcoming ;
  let section_project;

  const handleShow = () => {
    setShow(!show);
  } 

  if(show){
    section_upcoming = 'none';
    section_project = 'Block';
  }else {
    section_upcoming = 'Block';
    section_project = 'none';
  }
    return (
         
              <DivUpcomingPr name="upcoming-project">
              <div className="container">
                <div className="project">
                  <section id="upcoming-project" style={{display:`${section_upcoming}`}}>
                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-5">
                            <h2>
                              <span>Jogjatron</span> Upcoming Project
                            </h2>
                            <p>
                              Jogjatron akan terus mengembangkan project untuk menghadirkan implementasi teknologi blockchain
                            </p>
                            <button value="switch" className="btn btn-custom btn-primary" onClick={handleShow}>
                              Lihat Semua Project
                            </button> 

                        </div>

                        <div className="col-sm-12 col-md-6 col-lg-5">
                          <div className="illustrasi">
                            <ImgProjectAll />
                          </div>
                        </div>
                    </div>
                </section>
                
                <section id="project" style={{display:`${section_project}`}}>
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-6 col-xl-4">  
                      <div className="card">
                        <div className="illustrasi-2">
                          <ImgMO />
                          <img src="images/Happy-Hits.jpg" alt="project-jogjatron"></img>
                        </div>

                        <h2>Music Ownership (MO)</h2>
                        <p>
                          Memberikan akses bagi para fans kepada karya para musisi dengan memiliki 
                          Non Fungible Token (NFT) yang dikeluarkan oleh musisi sebagai perwujudan atau bukti bahwa 
                          pemilik NFT memiliki hak terhadap sebagian royalti atau pendapatan yang 
                          didapatkan musisi dari sebuah lagu atau sebuah album
                        </p>

                      </div>
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-6 col-xl-4">   
                      <div className="card">
                        <div className="illustrasi-2">
                          <ImgNFTTicket />
                          <img src="images/arzan.jpg" alt="project-jogjatron"></img>
                        </div>
                        <h2>NFT Ticket</h2>
                        <p>
                        Yogyakarta memiliki banyak destinasi wisata sehingga selalu menjadi tujuan 
                        favorit liburan NFT Tiket menjalin kerjasama jangka panjang 
                        dengan berbagai destinasi wisata dari museum, kebun binatang, resort, 
                        wisata desa masih banyak lagi. NFT Tiket juga akan terintegrasi penuh 
                        dengan NFT MO
                        </p>

                      </div>
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-6 col-xl-4">                              
                      <div className="card">
                        <div className="illustrasi-2">
                          <ImgNFTArtwork />
                          <img src="images/Listing-Hotel-Rooms-on-Airbnb-315x211.jpg" alt="project-jogjatron"></img>
                        </div>
                        <h2>NFT Artwork</h2>
                        <p>
                        Karya seni grafis menjadi karya NFT yang sering kita temui. Dengan NFT Artwork, mendorong kreator 
                        seni grafis baik di yogyakarta maupun dunia dalam berkarya dan mendigitalisasi karya mereka. 
                        </p>

                      </div>
                    </div>
                  </div>  
                  </section>
                </div>
              </div>
            </DivUpcomingPr>
            
            )
          
};

export default ThirdContent;