import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Axios from 'axios';
import icons8_telegram_60 from '../../assets/icons8-telegram-60.png';
import icons8_airdrop_60 from '../../assets/icons8-airdrop-60.png';
const REACT_APP_PROD_ROUTE = process.env.REACT_APP_PROD_ROUTE || 'http://127.0.0.1:8000/'


const ImgJoinTelegram = styled.img`
  width: 20px;
  height: 20px;
`;

ImgJoinTelegram.defaultProps = {
  src: icons8_telegram_60,
};

const Imgairdrop = styled.img`
  width: 30px;
  height: 30px;
`;

Imgairdrop.defaultProps = {
  src: icons8_airdrop_60,
};

const BtnAbout = styled.button`
 
`;

function AirdropCard() {
    
    const [timer, setTimer] = useState(0);

    const getDataCountdownAirdrop= () =>{
      Axios.get(`${REACT_APP_PROD_ROUTE}api/countdown-independence-day` 
      )
      .then((response) => {;
        setTimer(response.data * 1000);
      } );
    } 


    useEffect(() => {
      getDataCountdownAirdrop();
    }, []);

    if (timer > 0) {
    return (
      <>
      <section id="presale-info">
        <div className="box">
          <div className="container">
              <div className="row">
                  <div className="col-sm-8">
                      <div className="subheader-text">
                          Yuk, Join Airdrop!
                      </div>
                      <div className="subheader-subtext">
                          Gabung ke komunitas telegram untuk info lebih tentang Jogjatron
                      </div>
                  </div>
                  <div className="col-sm-4">
                    <a href="/Airdrop-event">
                      <BtnAbout className='btn btn-custom2 btn-secondary' ><Imgairdrop />   Join Airdrop </BtnAbout>
                    </a>
                    <button type="button" className="btn btn-custom btn-secondary" onClick={event =>  window.location.href='https://t.me/jogjatron'}>
                      
                      <ImgJoinTelegram />   
                    
                    </button>
                  </div>
                  
              </div>
          </div>
        </div>
      </section>
      </> );
    } else {

      return (
        <>
        <section id="presale-info">
          <div className="box">
            <div className="container">
                <div className="row">
                    <div className="col-sm-8">
                        <div className="subheader-text">
                            Yuk, Join Airdrop!
                        </div>
                        <div className="subheader-subtext">
                            Gabung ke komunitas telegram untuk info lebih tentang Jogjatron
                        </div>
                    </div>
                    <div className="col-sm-4">
                      <a href="/Airdrop-event">
                        <BtnAbout className='btn btn-custom btn-secondary' ><Imgairdrop />   Join Airdrop </BtnAbout>
                      </a>
                      <button type="button" className="btn btn-custom btn-secondary" onClick={event =>  window.location.href='https://t.me/jogjatron'}>
                        
                        <ImgJoinTelegram />   
                      
                      </button>
                    </div>
                    
                </div>
            </div>
          </div>
        </section>
        </> );
    }
  }
    


export default AirdropCard;