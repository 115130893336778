
import React, { useState, useEffect } from "react";

import { ethers } from "ethers";
import Web3Modal from "web3modal";
import WrapperAirdrop from './WrapperAirdrop';

import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { truncateAddress } from '../../utils/utils.js';
import { providerOptions } from "../../ProviderOptions";
import Axios from 'axios';
//useTasks to show, useTasksDispatch to edit
//const dispatch = useTasksDispatch();
//  dispatch({  walletAddress : 'newvalue'  })
//const tasks = useTasks();
import { useTasks, useTasksDispatch } from "../../TasksContext";


import LogoJogjatron_x7 from '../../assets/LogoJogjatron_x7.png';
import Gatotkaca from '../../assets/images/MaskotGatotVer01Rev01.png';
import iconIG from '../../assets/images/icons8_instagram_48.png';
import iconTw from '../../assets/images/icons8-twitter-48.png';
import iconTele from '../../assets/images/icons8_telegram_app_48.png';
import background from '../../assets/images/bg.png';
import Swal from "sweetalert2";
const REACT_APP_PROD_ROUTE = process.env.REACT_APP_PROD_ROUTE || 'http://127.0.0.1:8000/'

  const DivAirdr = styled.div`
  background: url(${background});
  padding-top: 10rem;
  padding-bottom: 10rem;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  `;

  const ImgLogo = styled.img`
  `;

  ImgLogo.defaultProps = {
  src: LogoJogjatron_x7,
  };

  const ImgGatot = styled.img`
  `;

  ImgGatot.defaultProps = {
  src: Gatotkaca,
  };

  const ImgIG = styled.img`
  `;

  ImgIG.defaultProps = {
  src: iconIG,
  };

  const ImgTw = styled.img`
  `;

  ImgTw.defaultProps = {
  src: iconTw,
  };


  const ImgTele = styled.img`
  `;

  ImgTele.defaultProps = {
  src: iconTele,
  };

function Airdrops(){
  const tasks = useTasks();
  const walletAddress= tasks.walletAddress;
  
  const [timer, setTimer] = useState('');

  const [Airdrop_telegramID, setAirdrop_telegramID] = useState("");
  const [Airdrop_twitterId, setAirdrop_twitterID] = useState("");
  const [Airdrop_instagramId, setAirdrop_instagramID] = useState("");
  const [agree, setAgree] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [showDivA, setShowDivA] = useState("Block");
  const [showDivB, setShowDivB] = useState("none");
  const [loading, setLoading] = useState('false');

  
  const handleCheckbox = () => {
    setAgree(!agree);
  }
  {/**Airdrop event */}
  const handleChangeTelegram = (event) => {
    setAirdrop_telegramID(event.target.value);
  }
  const handleChangeTwitter = (event) => {
    setAirdrop_twitterID(event.target.value);
  }
  const handleChangeInstagram = (event) => {
    setAirdrop_instagramID(event.target.value);
  }

  const changeDiv = () => {
    setLoading(!loading);
    if (!walletAddress){
      //alert('tidak ada address wallet terkoneksi');
      Swal.fire({
        icon: 'error',
        title: 'Gagal',
        text: `tidak ada address wallet terkoneksi`,
        allowOutsideClick: false
        })
        
        setLoading('false');
    }
    else if (!document.getElementById('twitterInput').value){
      //alert('silahkan masukkan username twitter');
      Swal.fire({
        icon: 'error',
        title: 'Gagal',
        text: `silahkan masukkan username twitter`,
        allowOutsideClick: false
        })
        
        setLoading('false');
    }
    else if (!document.getElementById('readwhitepaperchecked').checked){
      //console.log('unchecked');
      //alert('Silahkan checklist ketentuan membaca whitepaper');
      Swal.fire({
        icon: 'error',
        title: 'Gagal',
        text: `silahkan masukkan username twitter`,
        allowOutsideClick: false
        })
        
        setLoading('false');
    }else{
      setLoading(!loading);
      try {
        //event.preventDefault();
       
        Axios.post(`${REACT_APP_PROD_ROUTE}api/check-twitter-follower`,{
          username: Airdrop_twitterId
        }).then(res => {
            //console.log(res.data.data)
            if(res.status === 200){
              if (res.data.data === "sudah follow"){
                setLoading(!loading);
                registerAirdrop();
              }
              else if (res.data.data === "belum follow") {
                //alert('user '+ Airdrop_twitterId +' belum follow jogjatron twitter (JogjaTronNFT)')
                Swal.fire({
                  icon: 'error',
                  title: 'Gagal',
                  text: `user ${Airdrop_twitterId}  belum follow jogjatron twitter (JogjaTronNFT)`,
                  allowOutsideClick: false,
                  })
                  setLoading('false');
              }
            }
          })
      } catch (error) {
        console.log(error.response);
      }
    }
    
  }

  const registerAirdrop = () => {
    //event.preventDefault();
   
    try {

      Axios.post(`${REACT_APP_PROD_ROUTE}api/airdrop-users`,{
        wallet_address : walletAddress,
        twitter_username: Airdrop_twitterId, 
        instagram_username : Airdrop_instagramId,
        telegram_username : Airdrop_telegramID,
      } 
      )
      .then((response) => {
          Swal.fire({
            icon: 'success',
            title: 'Sukses',
            text: `${walletAddress} sukses mendaftarkan Airdrop JAGO`,
            allowOutsideClick: false
          })
          setShowDivA('none');
          setShowDivB('Block');
        
      } );
    }catch (error) {
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'error',
        text: `${error}`,
        allowOutsideClick: false
      })
      window.location.reload(false);
    }
  

  }

  //not used
  const checkTwitter = async ( ) => {
    try {
      //event.preventDefault();
     
      Axios.post(`http://127.0.0.1:5000/check-data-twitter`,{
        ids: Airdrop_twitterId
      }).then(res => {
          console.log(res);
          console.log(res.data[1]);
          alert(Airdrop_twitterId +' '+ res.data[1])
        })
    } catch (error) {
      console.log(error);
    }
  }
  

  useEffect(() => {
      Axios.get(`${REACT_APP_PROD_ROUTE}api/countdown-independence-day` 
      )
      .then((response) => {;
        setTimer(response.data * 1000);
      } );
     }, []);
  
  useEffect(() => {
   if(tasks.walletAddress.length > 0
      &&Airdrop_telegramID.length > 0 
      && Airdrop_instagramId.length > 0 
      && Airdrop_twitterId.length > 0
      && agree
       )
    {
     setIsValid(true);
   }else{
     setIsValid(false);
   }
  },);

  return(
    <>
                 <DivAirdr>
                    <section className="airdop">
                      <div className="container">
                        <WrapperAirdrop dataFromParent={timer}/>
                      </div>
                    </section>
                  </DivAirdr>

                  <div className="form-airdrop" id="form-airdrop" style={{display:showDivA}}>
                      <div className="container">
                              <div className="form-box">
                                  <div className="title">
                                      <h6>AIRDROP</h6>
                                      <h2>Form Join Airdrop</h2>
                                      <h5>Silahkan isi data-data dibawah ini</h5>
                                  </div>
                                    <div className="form-floating mb-3 col-lg-6 col-sm-12 col-md-9 mx-auto ">
                                    {!walletAddress ? (
                                      <input type="" className="form-control" id="floatingInput" value="" disabled/>
                                    ):(
                                      <input type="" className="form-control" id="floatingInput" value={walletAddress.toUpperCase()} disabled></input>
                                    )}
                                      
                                      <label style={{marginLeft:1+"rem"}}>Wallet address</label>
                                    </div>
                                    
                                    <div className="form-floating mb-3 col-lg-6 col-sm-12 col-md-9 mx-auto ">
                                      <input type="" className="form-control " id="floatingInput" placeholder="Username Telegram" 
                                        name="telegram"
                                        onChange={handleChangeTelegram} />
                                      <label style={{marginLeft:1+"rem"}}>Username Telegram</label>
                                    </div> 

                                    <div className="form-floating mb-3 col-lg-6 col-sm-12 col-md-9 mx-auto ">
                                      <input type="" className="form-control " id="twitterInput" placeholder="Username Twitter"
                                      name="twitter"
                                      onChange={handleChangeTwitter} />
                                      <label style={{marginLeft:1+"rem"}}>Username Twitter</label>
                                    </div>
                                   
                                    <div className="form-floating mb-3 col-lg-6 col-sm-12 col-md-9 mx-auto ">
                                      <input type="" className="form-control" id="floatingInput" placeholder="Username Instagram"
                                      name="instagram"
                                      onChange={handleChangeInstagram} />
                                      <label style={{marginLeft:1+"rem"}}>Username Instagram</label>
                                    </div>
                                    
                                    
                                    <div className="form-check mb-3">
                                      <input className="form-check-input" type="checkbox" value="" checked={agree} id="readwhitepaperchecked" onChange={handleCheckbox}/>
                                      <label className="form-check-label" style={{color:"white"}} >
                                          Saya sudah membaca whitepaper token jago
                                      </label>
                                    </div>
                                    
                                    {/* {timer > 0
                                      ? <button type="submit" className="btn-custom-aird col-6" disabled>
                                          Join Airdrop</button>
                                      : <button type="submit" className="btn-custom-aird col-6" disabled={!isValid} onClick={changeDiv}> 
                                      { !loading && (
                                     <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                     )}
                                     Join Airdrop
                                 </button> 
                                    }*/}

                                    {timer < 0
                                      ? <button type="submit" className="btn-custom-aird col-6" disabled>Join Airdrop</button>
                                      : !isValid
                                        ?  <button type="submit" className="btn-custom-aird col-6" disabled>Join Airdrop</button>
                                        :  <button type="submit" className="btn-custom-aird col-6" disabled={!isValid} onClick={changeDiv}>
                                           { !loading && (
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            )}
                                            Join Airdrop
                                            </button>
                                    } 

                              </div>
                          </div>
                      </div>

                      <div className="task-airdrop" style={{display:showDivB}}>
                      <div className="container">
                        <div className="form-box">
                            <div className="title-task">
                                <h6>AIRDROP</h6>
                                <h2>Satu Langkah Lagi!</h2>
                            </div>
                            <div className="mascot-airdrop">
                              <div className="row">
                                  <div className="col-sm-12 col-md-12 col-lg-12">
                                      <div className="circular" style={{ float:"right"}} > 
                                          <h3>Follow Social media kami untuk informasi lebih lanjut mengenai Jogjatron dan Token Jago</h3>
                                      </div>
                                  </div>
                              </div>
                              <div className="row">
                                  <div className="col-lg-5 col-md-6 col-sm-12">
                                      <ImgGatot />
                                  </div>
                                  <div className="col-lg-7 col-md-6 col-sm-12" style={{display:'flex', alignItems: 'center' }}>
                                      <ul>
                                          <li>
                                            <a href="https://www.instagram.com/jogjatron/" target="_blank">
                                              <ImgIG />
                                            </a>@jogjatron
                                          </li>
                                          <li>
                                            <a href="https://twitter.com/JogjaTronNFT?s=20&t=fjtxBhfn7bXNdgvEHl-HgA" target="_blank">
                                            <ImgTw />
                                            </a>@jogjatronNFT
                                          </li>
                                          <li>
                                            <a href="https://t.me/jogjatron" target="_blank">
                                            <ImgTele />
                                            </a>@jogjatron
                                          </li>
                                      </ul>
                                  </div>
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>


              </>
  )
}

export default Airdrops;