import React from 'react';
import styled from 'styled-components';
import Countdown from "react-countdown";
import PresaleStartInComponent from './PresaleStartInComponent';
import PresaleEndInComponent from './PresaleEndInComponent';

const currentTimeInEpoch = Math.floor(new Date().getTime()/1000.0)
const dayOfPresaleInEpoch = 1684022381;  //14 mei 2023
const endDayPresaleInEpoch = 1686697200;	//13 june 2023
//epoch 14 mei - epoch current 
const CDstartPresaleInEpoch = dayOfPresaleInEpoch - currentTimeInEpoch;	// now to 14 mei 00
const CDendPresaleInEpoch = endDayPresaleInEpoch - currentTimeInEpoch

const totalTime = CDstartPresaleInEpoch*1000 //2*(definedDay * definedHour * definedMin * definedSecond);        // 2 days

function PresaleContent() {
	if(currentTimeInEpoch > dayOfPresaleInEpoch) {
		return (   
			<div className="box_2">
					<div className="container">
							<Countdown date={Date.now() + CDendPresaleInEpoch*1000} renderer={PresaleEndInComponent}  />
					</div>
			</div>
					)
	}
	else{
		return (   
			<div className="box_2">
					<div className="container">
							<Countdown date={Date.now() + CDstartPresaleInEpoch*1000} renderer={PresaleStartInComponent} />
					</div>
			</div>
					)
	}
	
  
};


export default PresaleContent;