
import { useRoutes } from "react-router-dom";
import Airdrops from "../../components/Airdrops";
import Layout from "../../components/Layout";
import Homepage from "../../components/Homepage";
import NoMatch from "../../components/NoMatch";
import ReleasedProject from "../../components/Homepage/ReleasedProject";

function RoutesConfig() {
  let element = useRoutes([
    { element: <Layout />,
      children: [
        { path: "", element: <Homepage />},
        { path: "/Airdrop-event", element: <Airdrops />},
        { path: "*", element: <NoMatch />},
        { path: "/Released-project", element: <ReleasedProject />},
      ]
    },
    
  ]);
  return element;
  }

  export default RoutesConfig;