import imageAnnouncement from '../../assets/images/1675401135.png';
import background from '../../assets/images/bg.png';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import icons_external from '../../assets/icons8-external-link-24.png'

const DivReleasedProject = styled.div`
background: url(${background});
padding-top: 10rem;
padding-bottom: 10rem;
background-size: cover;
background-position: center center;
background-repeat: no-repeat;
`;

const ImgExternalLink = styled.img`
  width: 20px;
  height: 20px;
`;

ImgExternalLink.defaultProps = {
  src: icons_external,
};


function ReleasedProject () {
    return (
        <>
        <DivReleasedProject>
        <div className="released-project">
        <div className="container">
            <div className="title">
                Released Project
            </div>
            <div className="row">
                <div className="col-lg-5 col-md-12 col-sm-12">
                    <div className='image-project'>
                        <img src={imageAnnouncement} />
                    </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className='subtitle'>
                        TENTANG NFT MO
                    </div>
                    <div className='title-project'>
                        NFT MUSIC OWNER
                    </div>
                    <div className='desc'>
                        <p>
                        Memberikan akses bagi para fans kepada karya para musisi dengan memiliki Non Fungible Token (NFT) 
                        yang dikeluarkan oleh musisi sebagai perwujudan atau bukti bahwa pemilik NFT memiliki hak terhadap 
                        sebagian royalti atau pendapatan yang didapatkan musisi dari sebuah lagu atau sebuah album
                        </p>
                    </div>
                    <div className='button'>
                        {/* <Button href={"https://nftmo.jogjatron.com/"} className='primary' style={{backgroundColor: 'transparent', 
                        borderBottomColor: 'white', borderRight: 'none', borderTop: 'none', borderLeft: 'none', borderBottomWidth: '1.5px'}}>
                            <ImgExternalLink /> Kunjungi Website NFT MO 
                        </Button> */}
                        <a className='primary' href="https://nftmo.jogjatron.com/">
                            <ImgExternalLink /> Kunjungi Website NFT MO 
                        </a>
                    </div>
                </div>
            </div>
        </div>
        </div>
        </DivReleasedProject>
        </>
    )
}

export default ReleasedProject;