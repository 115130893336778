import React from 'react';
import styled from 'styled-components';
import MaskotGatotVer01Rev02 from '../../assets/images/MaskotGatotVer01Rev02.png';
import icons8_document_60 from '../../assets/icons8-whitepaper-token-60.png';


const ImgMascot = styled.img`
`;

ImgMascot.defaultProps = {
  src: MaskotGatotVer01Rev02,
};

const Imgwhitepaper = styled.img`
  width: 25px;
  height: 25px;
`;

Imgwhitepaper.defaultProps = {
  src: icons8_document_60,
};

function MascotInfo() {
            return (   
                  <div className="mascot">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-5">
                          <div className="circular"> 
                            <h3>Halo, Aku Jagoan!</h3>
                          </div>
                          <ImgMascot />   
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-7">
                           
                            <p style={{marginTop:69+"px" }}>Maskot Jagoan melambangkan Jogjatron yang berkomitmen untuk menjadi jagoan nft 
                            marketplace dari Jogja dan bagi seniman Jogja. Melambangkan komitmen untuk senantiasa teguh dan 
                            tangguh serta sifat jagoan gatotkaca lainnya.</p>
                            <p>Maskot ini diambil dari salah satu jagoan andalan di 
                            pewayangan yaitu Gatotkaca.</p>
                          
                            <div className="cta-button">
                              {/* <a href={documentWP} target="_blank" rel="noreferrer" download className="btn btn-custom btn-primary">
                                <Imgwhitepaper />  Whitepaper Token
                              </a>  */}
                            </div>                        
                        </div>
                    </div>
                </div>
            )
};

export default MascotInfo;