import React, {useContext} from "react";
import NavButtonConnect from "./NavButtonConnect";
import NavbarOrdi from "./NavbarOrdi";

function Navbar(props){
  return(
    <>  
      <div className="site-mobile-menu site-navbar-target">
      <div className="site-mobile-menu-header">
        <div className="site-mobile-menu-close mt-3">
          <span className="icon-close2 js-menu-toggle"></span>
        </div>
      </div>
      <div className="site-mobile-menu-body"></div>
    </div> 

    <div className="site-navbar-wrap">
      <div className="site-navbar-top">
        <NavButtonConnect 
         connectTo={props.connectTo} 
         onClickConnect={props.onClickConnect}
         onClickDisconnect={props.onClickDisconnect}
        />
      </div>
        <NavbarOrdi />
    </div> 
    </>
  )
}

export default Navbar;