import React from 'react';
import { RootContext } from '../../App';
import styled from 'styled-components';
import Backgroundfooter from '../../assets/images/background-footer.png';
import LogoJogjatron_x7 from '../../assets/LogoJogjatron_x7.png';
import icons8_instagram_48 from '../../assets/icons8-instagram-48(5).png';
import icons8_twitter_48 from '../../assets/icons8-twitter-48(1).png';

const FooterDiv = styled.footer`
  background: url(${Backgroundfooter});
  background-repeat: no-repeat;
  background-size: cover;
`;
const ImgIg = styled.img`
`;

ImgIg.defaultProps = {
  src: icons8_instagram_48,
};

const ImgTw  = styled.img`
`;
ImgTw.defaultProps = {
  src: icons8_twitter_48,
}

const ImgLogo = styled.img`
`;

ImgLogo.defaultProps = {
  src: LogoJogjatron_x7,
};

function Footer() {
  
      return (
            <FooterDiv>
              <div className="container">
                  <div className="row">
                      <div className="col-lg-5 col-md-5 col-sm-4 col-xs-12"> 
                          <ul className="adress">
                              <li>
                                <ImgLogo />
                              </li>
                                    
                              <li>
                                <p>Ekosistem NFT dan Metaverse dari Yogyakarta untuk Dunia</p>
                              </li>
                          </ul>
                      </div>

                      <div className="col-lg-3 col-md-3 col-sm-4 col-xs-12">
                          <ul className="contact">
                              <span>Menu</span>    
                              <li >
                                  <a href="#token-info">Tentang</a>
                                </li>
                                    
                                <li>
                                  <a href="#upcoming-project">Upcoming Project</a>
                                </li>
                                    
                                <li>
                                  <a href="#token-info">Token Jago</a>
                                </li>
                                    
                                <li>
                                  <a href="https://whitepaper.jogjatron.com/" target="_blank">Gitbook Whitepaper </a>
                                </li>
                                <li>
                                  <a href="https://tokenomic.jogjatron.com/" target="_blank">Gitbook Tokenomic </a>
                                </li>
                                <li>
                                  <a href="https://roadmap.jogjatron.com/" target="_blank">Gitbook Roadmap </a>
                                </li>
                          </ul>
                      </div>

                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                          <ul className="social">
                            <span>Kontak</span>    
                            <li >
                              <p>Jl. Magelang No.188, Karangwaru, 
                              Kec. Tegalrejo, Kota Yogyakarta, Daerah Istimewa Yogyakarta 55242</p>
                            </li>
                            
                            <li>
                              <a href="mailto:hi@jogjatron.com">
                                <p>hi@jogjatron.com</p>
                              </a>
                            </li>
                              
                            <li>
                                <p>+62 811 2641 112</p>
                            </li>
                            
                            <li>
                              <a href="https://www.instagram.com/jogjatron/" target="_blank" rel="noreferrer">
                                <ImgIg />
                              </a>
                              <a href="https://twitter.com/JogjaTronNFT?s=20&t=fjtxBhfn7bXNdgvEHl-HgA" target="_blank" rel="noreferrer">
                                <ImgTw />
                              </a>
                            </li>
                              
                          
                          </ul>
                      </div>
                  </div>
              </div>
            </FooterDiv>
    )
}

export default Footer;